export interface Theme {
    name: string;
    properties: any;
  }
  
  export const bnpGreen: Theme = {
    name: "BNPgreen",
    properties: {
        "--background": "#f0f0f0",
        "--main-general": "#006852",
        "--main-lighter": "#16a86f",
        "--main-darker": "#003d30",
        "--panel-background": "#fff",
        "--text-font": "#212529",
        "--input-text": "#fff",
        "--input-text-focus": "#f0f0f0",
        "--input-border":"#02755d",
        "--input-active":"#28ac8f",
        "--input-active-border":"#0e8168",
        "--input-shadow": "rgba(0,84,62,.5)",
        "--input-shadow-hover": "rgba(0,104,82,.5)",
        "--input-hover": "#16a86f",
        "--ngx-datatable-row-even": "rgba(13, 141, 113, 0.04)",
        "--ngx-datatable-row-odd": "#fff",
        "--ngx-datatable-pager-number": "#fff"

    }
  };
  
  export const darkmode: Theme = {
    name: "DarkMode",
    properties: {
        "--background": "#494949",
        "--main-general": "#000",
        "--main-lighter": "lightgrey",
        "--main-darker": "dimgrey",
        "--panel-background": "#7a7a7a",
        "--text-font": "#fff",
        "--input-text": "#c7c7c7",
        "--input-text-focus": "#f0f0f0",
        "--input-border":"#fff",
        "--input-active":"lightgrey",
        "--input-active-border":"#000",
        "--input-shadow": "dimgrey",
        "--input-shadow-hover": "#000",
        "--input-hover": "dimgrey",
        "--ngx-datatable-row-even": "#454545",
        "--ngx-datatable-row-odd": "lightgrey",
        "--ngx-datatable-pager-number": "#000"
    }
  };