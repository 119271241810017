import { Component} from '@angular/core';
import { User } from 'src/app/models/entities/user/User';
import { AccountService } from 'src/app/services/account.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {


  public  user :User;
  
  constructor(
    private accountService: AccountService,
    private themeService: ThemeService
    ) {
      this.accountService.user.subscribe(x => (this.user = x));
  }


  logout() {
    this.accountService.logout();
  }

  toggleTheme() {
    if (this.themeService.isBnpTheme()) {
      this.themeService.setDarkmodeTheme();
    } else {
      this.themeService.setBnpTheme();
    }
  }
}
