<header id="app-header">
  <nav class="navbar navbar-expand-md navbar-dark" role="navigation">
    <a class="navbar-brand" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <div id="navbar-logo-container">        
        <img id="icon-bnp" src="../assets/images/icon-bnp.bmp" width="30" height="30" loading="lazy" alt="Bnp"> 
        BNP backoffice API
      </div>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
        <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="navbar-collapse collapse" id="collapsingNavbar">
        <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" routerLink="/accounts" routerLinkActive="active" *ngIf="user?.isAdmin">Manage users</a>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" >
            <a class="nav-link" *ngIf="user?.isAdmin" routerLink="accounts/{{user.id}}">   
              <img id="icon-profile" src="../assets/images/icon-profile.png" alt="Your account settings">
              <span id="profile-name">{{user.userName}}</span>
              <span id="profile-label">Profile</span>
            </a>          
          </li>

          <li class="nav-item" >
            <a class="nav-link" (click)=" toggleTheme()">
                <img src="../assets/images/icon-darkmode.png" alt="Switch theme">
            </a>
          </li>

          <li class="nav-item" >
            <a class="nav-link" (click)="logout()" *ngIf="user && user.isAdmin">
              <span>
                <img id="icon-logout" src="../assets/images/icon-logout.png" alt="Disconect your account">
                Disconnect
              </span>
            </a>
            <a class="nav-link" routerLink="account/login" *ngIf="!user || !user.isAdmin">
              <img id="icon-login" src="../assets/images/icon-login.png" alt="Acces to your personnal account">
              Login
            </a>          
          </li>



      </ul>
    </div>
    
  </nav>
</header>   
