import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/entities/user/User';
import { EntityService } from './entity.service';
import { AddEditUserRequest } from '../models/entities/user/request/add-edit.request';
import { ResetPasswordRequest } from '../models/entities/account/reset-password.request';
import { UpdateUserSettings } from '../models/entities/referentiel/user-settings/update-user-settings.entity';
import { LoginRequest } from '../models/entities/account/login.request';
import { UserSettings } from '../models/entities/referentiel/user-settings/get-user-settings';

@Injectable({ providedIn: 'root' })
export class AccountService extends EntityService<User> {

  public get userValue(): User {
    return this.userSubject.value;
  }

  public user: Observable<User>;
  private userSubject: BehaviorSubject<User>;

  constructor(private router: Router, private http: HttpClient) {
    super();
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/${id}`).pipe(
      map(x => {
        // auto logout if the logged in user deleted their own record
        if (id === this.userValue.id) {
          this.logout();
        }
        return x;
      })
    );
  }

  forgotPassword(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/Auth/forgot-password`, {email});
  }

  getAll() {
    return this.http.get<any[]>(`${environment.apiUrl}/accounts`);
  }

  getById(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/accounts/${id}`);
  }

  getUserSettings(id: string) {
    return this.http.get<UserSettings>(`${environment.apiUrl}/accounts/${id}/settings`);
  }

  // On recupere le user a la sortie du login, il faut le mapper correctement avec le token, ce qui n'est pas fait....
  login(loginRequest: LoginRequest) {
    const user = this.createInstance();
    return this.http
      .post<any>(`${environment.apiUrl}/Auth/login`, loginRequest)
      .pipe(
        map(loginResponse => {
            user.userName = loginRequest.userName;
            user.authToken = loginResponse.authToken;
            user.id = loginResponse.id;
            this.userSubject.next(user);
            return user;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/account/login']);
  }

  register(user: AddEditUserRequest) {
    return this.http.post(`${environment.apiUrl}/accounts/${user.id}`, user);
  }

  resetPassword(id: string, resetPasswordRequest: ResetPasswordRequest) {
    return this.http.patch(`${environment.apiUrl}/accounts/${id}/reset-password`, resetPasswordRequest);
  }

  setUserSettings(updateUserSettings: UpdateUserSettings) {
    return this.http.put(`${environment.apiUrl}/accounts/${updateUserSettings.userId}/settings`, updateUserSettings);
  }

  update(user: AddEditUserRequest) {
    return this.http.put(`${environment.apiUrl}/accounts/${user.id}`, user);
  }

  updateLocalStorageUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    this.userSubject.next(user);
  }

  getSwaggerUri(){
    return (environment.swaggerUrl);
  }

  protected _createInstance(data: any): User {
    return Object.assign(this._getDefaultData(), {
      id: data.id,
      userName: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.companyName,
      email: data.email,
      isActive: data.isActive,
      authToken: data.authToken,
      isAdmin: data.isAdmin,
      password: data.password,
      confirmPassword: data.confirmPassword,
      apiList: data.apiList,
      bddList: data.bddList,
    });
  }

  protected _getDefaultData(): User {
    return {
      id: null,
      userName: null,
      firstName: null,
      lastName: null,
      companyName: null,
      email: null,
      isActive: false,
      authToken: null,
      isAdmin: false,
      password: null,
      confirmPassword: null,
      apiList: null,
      bddList: null,
    };
  }
}
