import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AccountService } from 'src/app/services/account.service';
import { AlertService } from 'src/app/services/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService, private alertService: AlertService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
          if (err.error?.length > 0 ) {
            err.error.forEach(error => {
              this.alertService.error(error.description, {autoClose: true, keepAfterRouteChange: true });
            });
          }

          // si unauthorize ou forbidden, on logout.
          if (err.status === 401 || err.status === 403) {
            this.accountService.logout();
            return throwError("You're not allowed to connect with your account")
          }

          const error = err.error?.message || err.statusText;
          return throwError(error);
      })
    );
  }
}
