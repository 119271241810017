import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ConfigurationService } from './shared/services/env-configuration.service';
import { ENV_CONFIG } from './shared/environment';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppRoutingModule } from './app-routing.module';
import { AlertComponent } from './shared/components/alert/alert.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { LoadingScreenInterceptor } from './shared/interceptors/loading.interceptor';
import { LoaderComponent } from './shared/components/loader/loader.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

function configurationInit(config: ConfigurationService) {
  return () => config.init();
}

function getConfiguration(config: ConfigurationService) {
  return config.getConfigurationUrl();
}

@NgModule({

  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    HeaderComponent,
    LoaderComponent
  ],

  imports: [
    AppRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDatatableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,useFactory: HttpLoaderFactory,deps: [HttpClient]
      }
    })
  ],

  providers: [
    {provide: APP_INITIALIZER, useFactory: configurationInit, deps: [ConfigurationService], multi: true},
    {provide: ENV_CONFIG, useFactory: getConfiguration, deps: [ConfigurationService]},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true}
  ],

  bootstrap: [AppComponent],

})
export class AppModule {}
