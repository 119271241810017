// get api url from env-config.json in assets
export const environment = {
  production: false,
  apiUrl: 'https://localhost:44304//api',
  apiVersion: '',
  versions: {
    version: '0',
    revision: '1',
    branch: '0'
  },
  swaggerUrl: 'https://localhost:44304/swagger/index.html'
};
