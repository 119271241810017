import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AuthGuard } from './shared/utils/auth.guard';

const accountModule = () => import('./features/account/account.module').then(x => x.AccountModule);
const usersModule = () => import('./features/users/users.module').then(x => x.UsersModule);

const routes: Routes = [
  // route quand on arrive sur l'app :
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  // route pour appeler un module foRoot avec son -routing.module :
  // { path: 'home', redirectTo: '/home', pathMatch: 'full' },
  // route pour lazyload un module :
  // { path: 'lazy', loadChildren: () => import('./features/lazy/lazy.module').then((m) => m.LazyModule) },
  // route pour appeler directement un component :
  // { path: 'direct', component: DirectComponent }

  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'accounts', loadChildren: usersModule, canActivate: [AuthGuard] },
  { path: 'account', loadChildren: accountModule },
    // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
