import { Component } from '@angular/core';
import { User } from 'src/app/models/entities/user/User';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    
    public get user(): User {
        return JSON.parse(localStorage.getItem('user'));
    }

}
