import { AlertType } from 'src/app/models/enums/AlertType';

export class Alert {
  autoClose: boolean;
  fade: boolean;
  id: string;
  keepAfterRouteChange: boolean;
  message: string;
  type: AlertType;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}
