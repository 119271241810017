<!-- <h1>
  <h2>{{ 'appTitle' | translate }}</h2>
</h1>
  <p>appText: </p>
  <div>
    <button (click)="useLanguage('en')">English</button>
    <button (click)="useLanguage('fr')">French</button>
  </div> -->
<app-header></app-header>
<app-loader></app-loader>
<app-alert></app-alert>
<router-outlet></router-outlet>

