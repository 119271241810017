import { Injectable } from '@angular/core';
import { Theme, bnpGreen, darkmode} from '../theme/theme';

@Injectable({
  providedIn: 'root'
})

export class ThemeService {
  private active: Theme = bnpGreen;
  private availableThemes: Theme[] = [bnpGreen, darkmode];

  getActiveTheme(): Theme {
    return this.active;
  }

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  isBnpTheme(): boolean {
    return this.active.name === bnpGreen.name;
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }

  setBnpTheme(): void {
    this.setActiveTheme(bnpGreen);
  }

  setDarkmodeTheme(): void {
    this.setActiveTheme(darkmode);
  }
}
