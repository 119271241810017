import { Component, OnInit, OnDestroy } from '@angular/core';
import { LooKey } from './shared/utils/looKey';
import { withDestroy } from './shared/mixins/with-destroy.mixin';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends withDestroy() implements OnInit, OnDestroy {
  public start = false;
  title = 'angular-9-i18n';
  private keyList = [];

  langs = ['en', 'fr'];

  constructor(private translateService: TranslateService) {
    super();
  }

  keyup = ($event: any): void => {
    this.keyList = LooKey.karaOwaru($event, this.keyList);
    if (this.keyList.length === 11) {
      this.start = true;
    } else {
      this.start = false;
    }
  };

  ngOnDestroy() {
    window.removeEventListener('keyup', this.keyup, true);
    super.ngOnDestroy();
  }

  ngOnInit() {
    const browserlang = this.translateService.getBrowserLang();
    if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    } else {
      this.translateService.setDefaultLang('en');
    }

    window.addEventListener('keyup', this.keyup, true);
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }
}
