import {Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})

export class LoaderComponent implements OnInit {
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  loading = false;
  loadingSubscription: Subscription;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loadingSubscription = this.loaderService.isLoading.pipe(
      debounceTime(200)
    ).subscribe((value) => {
      this.loading = value;
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
